import React, { useState, useEffect, useRef } from 'react';
import "./style.scss";
import { NMCKEditor } from "../CKEditor";
import { Button } from "../Button";
import { Menu } from "../Menu";
import { Header, Footer, HistoryTable } from "./Components";
import { getDocumenterPages, Sections, organizePagesSections } from "./PageHelper";;

const DocumentTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '12%', className: 'exo2 f10 txt-left caps', cellClassName: 'f9 txt-left caps' },
  { title: 'Version', dataIndex: 'version_no', key: 'version_no', width: '8%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center' },
  { title: 'Name', dataIndex: 'username', key: 'username', width: '28%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Designation, Function', dataIndex: 'designation_function', key: 'designation_function', width: '32%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '20%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center caps' }
]
const ChangeTableCols = [
  { title: 'Action', dataIndex: 'action', key: 'action', width: '12%', className: 'exo2 f10 txt-left caps', cellClassName: 'f9 txt-left' },
  { title: 'Version', dataIndex: 'version', key: 'version', width: '8%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Responsible', dataIndex: 'responsible', key: 'responsible', width: '25%', className: 'exo2 f10 txt-left', cellClassName: 'f9 txt-left' },
  { title: 'Description', dataIndex: 'summary', key: 'summary', width: '35%', className: 'exo2 f10 txt-left', isHTML: true, cellClassName: 'f9 txt-left' },
  { title: 'Date', dataIndex: 'created_at', key: 'created_at', width: '20%', className: 'exo2 f10 text-center', cellClassName: 'f9 text-center' }
]

const DocumentMenus = [
  { id: 'prev', label: "Move to Previous Page" },
  { id: 'next', label: "Move to Next Page" },
  { id: 'continue', label: "Continue to Next Page" }
]
const DocHistoryRows = [
  { action: 'Approved', version: '', name: '[[CEOFullname]]', desc: 'Chief Executive Officer', date: '[[Ddd, dd-Mmm-yyyy]]' },
  { action: 'Approval', version: '', name: '[[ApproverFullname]]', desc: 'Head of Department, [[ApproverDept]]', date: 'Pending' },
  { action: 'Revision', version: '', name: '[[ReviewerFullname]]', desc: '', date: 'Review' },
  { action: 'Draft', version: '101', name: '[[DocCreatorFullname]]', desc: '[[FullDesignation]], InfoSec', date: '[[Ddd, dd-Mmm-yyyy]]' },
]
const ChangeHistoryDefaultRows = [
  { action: ' ', version: '', name: '', desc: '1.', date: '' }
]
const addContextMenu = (editor) => {
  const contextMenuDefinition = [
    {
      label: 'Custom Option 1',
      command: 'customOption1',
      group: 'customGroup',
    },
    {
      label: 'Custom Option 2',
      command: 'customOption2',
      group: 'customGroup',
    },
  ];
  editor.addCommand('customOption1', {
    exec: function (editorInstance) {
      console.log('Custom Option 1 clicked');
    },
  });

  editor.addCommand('customOption2', {
    exec: function (editorInstance) {
      console.log('Custom Option 2 clicked');
    },
  });
  editor.on('contentMenu', function (event) {
    console.log('ed');
    event.data = contextMenuDefinition;
  });
}

const PageSection = (props) => {
  const { isSchemaOnly, section, inEditMode, onMove, onEditorReady, onChange } = props;
  const [menuElem, setMenuElem] = useState(null);
  useEffect(() => {
    if (inEditMode) {
      if (isTableSection() || section.attribute === 'tbl_of_content') {
        handleEditorReady({ editor: '' })
      }
    }
  }, [inEditMode])
  const ref = useRef(null)
  const handleClick = (e, menu) => {
    setMenuElem(null);
    let val = ref.current ? ref.current.getData() : '';
    onMove && onMove(menu.id, val)
  }
  const handleEditorReady = (e) => {
    const editor = e.editor;
    if (editor) {
      ref.current = editor;
      addContextMenu(editor)
    }
    onEditorReady && onEditorReady({ attribute: section.attribute, showSectionTitle: section.showSectionTitle }, editor)
  }
  const isTableSection = () => {
    return section.attribute === 'change_history' || section.attribute === 'doc_history'
  }
  const getTableColumns = () => {
    return section.attribute === 'change_history' ? ChangeTableCols : DocumentTableCols
  }
  const getTableRows = () => {
    return (
      isSchemaOnly ? (
        section.attribute === 'change_history' ? ChangeHistoryDefaultRows : DocHistoryRows
      ) : (section.value || [])
    )
  }
  const getSectionHTMLValue = () => {
    // temp fix, replace all nbsp
    let value = section.value;
    value = value.replace(/&nbsp;/g, ' ');
    // console.log(section.attribute, value);
    return value
  }
  return (
    <div className={`col section ${inEditMode ? 'edit-mode' : ''}`} id={`section-${section.attribute}`}>
      <div className='col w-100'>
        {
          Boolean(section.showSectionTitle) &&
          <div className='row w-100 title'>
            <strong className={`f7 bold exo2 ${isSchemaOnly ? 'c882222' : 'c238787'} caps`}>
              {Boolean(section.slNo) && <span className='sl-no'>{section.slNo}.</span>}
              <span dangerouslySetInnerHTML={{ __html: section.title }}></span>
            </strong>
            {
              inEditMode && section.attribute !== 'doc_history' &&
              <Button
                font='f12' icon="icon-profile-caret f12"
                color="c238787" className="title-menu"
                onClick={(e) => {
                  console.log(e.currentTarget, e.target);
                  setMenuElem(e.currentTarget)
                }} />
            }
          </div>
        }
        <div className={`col section-content w-100 ${section.showSectionTitle && !section.slNo ? 'no-pad' : ''}`}>
          <div className='col w-100'>
            {
              (isTableSection() && getTableRows().length > 0) ?
                <HistoryTable
                  isSchemaOnly={isSchemaOnly}
                  rows={getTableRows()}
                  Columns={getTableColumns()} />
                : section.attribute === 'tbl_of_content' ?
                  <div dangerouslySetInnerHTML={{ __html: section.value || '' }}></div>
                  :
                  <React.Fragment>
                    {
                      inEditMode ?
                        <React.Fragment>
                          <NMCKEditor
                            type="inline"
                            initialData={getSectionHTMLValue()}
                            onChange={onChange}
                            onInstanceReady={handleEditorReady} />
                          {
                            Boolean(props.allowAddVariable && (section.allowAddVariable !== undefined ? section.allowAddVariable : true)) &&
                            <Button label='Add Variables' className='bg59A7AC btn-add' onClick={() => {
                              props.onAddVariable && props.onAddVariable(section.attribute, ref.current)
                            }} />

                          }
                        </React.Fragment>
                        :
                        <div className='section-content-html' dangerouslySetInnerHTML={{ __html: getSectionHTMLValue() || '' }}></div>
                    }
                  </React.Fragment>
            }
          </div>
        </div>
      </div>
      <Menu
        id={`${section.attribute}`}
        menuItems={DocumentMenus}
        anchorEl={menuElem}
        onMenuClick={handleClick}
        onClose={() => setMenuElem(null)} />
    </div>
  )
}

const Page = (props) => {
  const { isSchemaOnly, pageNo, document, sections, onSectionMove, onEditorReady, zoomLevel } = props;
  const getZoomLevel = () => {
    let style = {}, currentZoomLevel = zoomLevel;
    if (zoomLevel === 'fit') {
      let viewerContainer = window.document.querySelector('.document-viewer');
      if (viewerContainer) {
        currentZoomLevel = parseInt(((viewerContainer.clientWidth - 10) / 793) * 100);
      }
    }
    if (currentZoomLevel && currentZoomLevel !== 100) {
      let scale = currentZoomLevel / 100;
      let pageHeight = 29.7;
      style.transform = `scale(${scale})`;
      if (scale === 0.5 && pageNo === 0) {
        style.marginTop = '-7.4cm'
      } else {
        style.marginTop = pageHeight;
        if (pageNo === 0) {
          style.marginTop = (pageHeight / 2) + scale;
        }
        style.marginTop = `${style.marginTop * (scale - 1)}cm`
      }
      if (pageNo === (props.totalPages - 1)) {
        if (scale === 0.5) {
          style.marginBottom = '0px';
        } else {
          let rem = currentZoomLevel % 100;
          rem = rem ? rem : 100;
          style.marginBottom = `${4 * (rem / 25)}cm`;
        }
      }
    }
    return style
  }
  return (
    <div className={`col page ${isSchemaOnly ? 'bgFFDDDD' : 'bgDDFFFF'}`} id={`page-${pageNo + 1}`} style={getZoomLevel()}>
      <Header {...props} pageNo={pageNo + 1} />
      <div className='col f-rest o-hide'>
        <div className='col w-100 y-100 oy-auto page-content'>
          {
            pageNo === 0 &&
            <h1 className={`exo2 bold f2 ${isSchemaOnly ? 'c882222' : 'c238787'}  text-center doc-title`}>{!isSchemaOnly ? document.name : '[[DocumentTitle]]'}</h1>
          }
          {
            sections.map((section, index) => {
              return (
                <PageSection
                  {...props}
                  key={section.attribute}
                  section={section}
                  onMove={(action, value) => {
                    onSectionMove && onSectionMove(action, value, index)
                  }}
                  onEditorReady={(sectionConfig, editor) => {
                    onEditorReady && onEditorReady(pageNo, index, sectionConfig, editor)
                  }}
                />
              )
            })
          }
        </div>
      </div>
      <Footer  {...props} pageNo={pageNo + 1} />
    </div>
  )
}

export const DocumentEditor = (props) => {
  const { document, user, isSchemaOnly, saveData, onChange, zoomLevel, inEditMode } = props;
  const [pages, setPages] = useState(null)
  const sectionEditors = useRef([])
  useEffect(() => {
    let timer
    if (document && !pages) {
      const options = { document, user, isSchemaOnly }
      let pages = getDocumenterPages(options);
      setPages(pages)
      timer = setTimeout(() => {
        if (timer) {
          clearTimeout(initSectionLinks)
        }
        initSectionLinks();
        // organizePagesSections(pages);
      }, 1000)
    }
  }, [document]);
  useEffect(() => {
    initSectionLinks()
  }, [inEditMode])
  const initSectionLinks = () => {
    let allLinks = window.document.querySelectorAll('.section-content-html a');
    Array.prototype.map.call(allLinks, (link) => {
      link.addEventListener('click', handleContentLink)
    })
    if (inEditMode) {
      Array.prototype.map.call(allLinks, (link) => {
        link.removeEventListener('click', handleContentLink)
      })
    }
  }
  const handleContentLink = (e) => {
    window.open(e.target.href, '_blank');
    e.preventDefault();
  }
  const handleSaveData = () => {
    let body = {}, document = {}, pageConfig = [];
    sectionEditors.current.forEach((sections, index) => {
      pageConfig[index] = []
      sections.forEach((section, j) => {
        const { editor, ...rest } = section;
        let key = `${index}_${j}`;
        pageConfig[index][j] = { ...rest, key };
        if (!body[section.attribute]) {
          body[section.attribute] = {};
        }
        body[section.attribute][key] = (editor && editor.getData) ? editor.getData() : ''
      })
    });
    for (const attribute in body) {
      if (Object.hasOwnProperty.call(body, attribute)) {
        document[attribute] = JSON.stringify(body[attribute])
      }
    }
    document.page_config = JSON.stringify(pageConfig);
    return document
  }
  const handleChange = (e) => {
    let document;
    if (saveData) {
      document = handleSaveData()
    }
    onChange && onChange(e, document)
  }
  const handleEditorReady = (page, index, sectionConfig, editor) => {
    if (!sectionEditors.current[page]) {
      sectionEditors.current[page] = [];
    }
    sectionEditors.current[page][index] = { ...sectionConfig, editor: editor };
  }
  const handleEditorSectionMoved = (action, pageNo, index) => {
    switch (action) {
      case 'remove':
        sectionEditors.current[pageNo].splice(index, 1);
        if (sectionEditors.current[pageNo].length === 0) {
          sectionEditors.current.splice(pageNo, 1);
        }
        break;
      case 'add-begin':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].unshift({})
        break;
      case 'add-end':
        if (!sectionEditors.current[pageNo]) {
          sectionEditors.current[pageNo] = [];
        }
        sectionEditors.current[pageNo].push({})
        break;
    }
  }
  const handleSectionMove = (action, value, sectionIndex, pageNo) => {
    const currentPages = pages.map((sections) => {
      return sections.map(_ => ({ ..._ }))
    });
    let section = currentPages[pageNo][sectionIndex], newPageNo;
    switch (action) {
      case DocumentMenus[0].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo - 1;
        currentPages[newPageNo].push({ ...section, value: value });
        if (currentPages[pageNo].length === 0) {
          currentPages.splice(pageNo, 1);
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-end', newPageNo)
        break;
      case DocumentMenus[1].id:
        currentPages[pageNo].splice(sectionIndex, 1);
        newPageNo = pageNo + 1;
        if (!currentPages[newPageNo]) {
          currentPages.push([{ ...section, value: value }])
        } else {
          currentPages[newPageNo].unshift({ ...section, value: value })
        }
        handleEditorSectionMoved('remove', pageNo, sectionIndex)
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
      case DocumentMenus[2].id:
        let newSection = { ...section, showSectionTitle: false, value: '' }
        currentPages.forEach((sections, index) => {
          let found = sections.find((_) => _.attribute === section.attribute);
          if (found) {
            newPageNo = index;
          }
        })
        newPageNo++;
        if (!currentPages[newPageNo]) {
          currentPages.push([newSection])
        } else {
          currentPages[newPageNo].unshift(newSection)
        }
        handleEditorSectionMoved('add-begin', newPageNo)
        break;
    };
    setPages(currentPages)
  }
  return (
    <div className='col w-100 h-100 oy-auto document-viewer'>
      {
        Boolean(pages) &&
        <React.Fragment>
          {
            pages.map((sections, index) => {
              return (
                <Page
                  {...props}
                  zoomLevel={zoomLevel}
                  key={index}
                  sections={sections}
                  pageNo={index}
                  totalPages={pages.length}
                  onEditorReady={handleEditorReady}
                  onChange={handleChange}
                  onSectionMove={(action, value, sectionIndex) => handleSectionMove(action, value, sectionIndex, index)}
                />
              )
            })
          }
        </React.Fragment>
      }
    </div>
  )
}
export const PageSections = Sections;